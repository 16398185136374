@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;
}


/* landing page */


.home-page header {
  background-image: url(./img/bg1.jpg);
  background-size: cover;
  background-position: center;
}

.home-page .public-logo {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-page h1 {
  font-size: 8em;
  color: #fff;
  font-weight: 600;
  transition: 0.5s;
}

.home-page .shadow {
  backdrop-filter: blur(10px);
  padding: 0 4px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.home-page h1:hover {
  -webkit-text-stroke: 2px #fff;
  color: transparent;
}

.home-page h3 {
  color: #fff;
  font-weight: 100;
}

.home-page .link {
  text-decoration: none;
  display: inline-block;
  color: white;
  font-size: 24px;
  border: 4px solid #364285a1;
  padding: 14px 70px;
  border-radius: 45px;
  margin-top: 20px;
  transition: 0.5s;
  backdrop-filter: blur(20px);
}

.home-page .link:hover {
  color: #fffffffc;
  border: 4px solid #fffffff7;
}


/* login page */


.login-page {
  background-image: url(./img/bg1.jpg);
  background-size: cover;
  background-position: center;
}

.wrapper {
  width: 100%;
  max-width: 420px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, .2);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  color: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  margin: 0 auto;
}

.wrapper h1 {
  font-size: 36px;
  text-align: center;
  color: #223145db;
}

.wrapper .input-box {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 30px 0;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, .2);
  border-radius: 40px;
  font-size: 16px;
  color: #fff;
  padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
  color: #fff;
}

.wrapper .checkbox-wrapper-18 .round {
  position: relative;
}

.wrapper .checkbox-wrapper-18 .round label {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: block;
}

.wrapper .checkbox-wrapper-18 .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 9px;
}

.wrapper .checkbox-wrapper-18 .round input[type="checkbox"] {
  visibility: hidden;
  display: none;
  opacity: 0;
}

.wrapper .checkbox-wrapper-18 .round input[type="checkbox"]:checked+label {
  background-color: #008080cd;
  border-color: #353535;
}

.wrapper .checkbox-wrapper-18 .round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.wrapper .btn {
  width: 100%;
  height: 45px;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.wrapper .reg-link {
  font-size: 14.5px;
  text-align: center;
  margin: 20px 0 15px;
}

.wrapper .reg-link .link {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.wrapper .reg-link .link:hover {
  text-decoration: underline;
}


@media (min-width: 576px) and (max-width: 767px) {
  .wrapper {
    margin-right: 20px;
  }
}



/* 404 page */

#error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
}

#error-page .content {
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

#error-page h4 {
  font-size: 36px;
  margin-bottom: 20px;
}

#error-page p {
  font-size: 18px;
  margin-bottom: 20px;
}

.link2 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #717378;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color .3s;
}

.link2:hover {
  background-color: #008080;
}


/* welcome page */

.welcome-links {
  text-decoration: 'underline';
  color: rgb(16, 16, 16);
  margin-bottom: 10px;
}

.welcome-links:hover {
  color: grey;
  transition: color 0.5s ease;
}


/* institute page */

.institute-list {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 15px;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

.institute-list::-webkit-scrollbar {
  width: 12px;
}

.institute-list::-webkit-scrollbar-track {
  background: transparent;
}

.institute-list::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: 3px solid transparent;
}

.institute-Button {
  margin-top: 5px;
  border-radius: 15px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.institute-Button:hover {
  background-color: #008080cd;
  color: white;
}

.institute-Button2 {
  margin-top: 5px;
  border-radius: 15px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.institute-Button2:hover {
  background-color: #a30000cd;
  color: white;
}

.institute-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.institute-list li {
  padding: 5px 0;
}


/* logs page */

.log-content {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 15px;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

.log-content::-webkit-scrollbar {
  width: 12px;
}

.log-content::-webkit-scrollbar-track {
  background: transparent;
}

.log-content::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: 3px solid transparent;
}

/* templates page */

.react-quill {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
  margin-top: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}


.react-quill1 {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
  margin-top: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.react-quill::-webkit-scrollbar {
  width: 12px;
}

.react-quill::-webkit-scrollbar-track {
  background: transparent;
}

.react-quill::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: 3px solid transparent;
}

.react-quill1::-webkit-scrollbar {
  width: 12px;
}

.react-quill1::-webkit-scrollbar-track {
  background: transparent;
}

.react-quill1::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: 3px solid transparent;
}

.template-select {
  border-radius: 6px;
  padding: 4px 8px;
  border: 2px solid #ccc;
  margin: 5px 0;
}

.autocomplete-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 150px;
  max-width: 60%;
  z-index: 99;
  overflow-y: auto;
}

.autocomplete-suggestion {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-suggestion:hover {
  background-color: #f0f0f0;
}

.template-Button1,
.template-Button2 {
  margin-top: 10px;
  border-radius: 15px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.template-Button1:hover {
  background-color: #008080cd;
  color: white;
}

.template-Button2:hover {
  background-color: #a30000cd;
  color: white;
}

@media (max-width: 311px) {
  .template-select {
    width: 50%;
  }
}


/* chat box */

.chat-container {
  padding-top: 6rem;
  padding-bottom: 7rem;
}

.message-box {
  position: relative;
  height: 400px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px;
}

.message-list {
  list-style-type: none;
  padding-left: 0;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #717378cd transparent;
}

.message-list::-webkit-scrollbar {
  width: 8px;
}

.message-list::-webkit-scrollbar-thumb {
  background-color: #DCF8C6;
}

.message-list li {
  margin-bottom: 10px;
}

.message-list .left-align {
  text-align: left;
  background-color: #969292cd;
  border-radius: 7.5px;
  color: white;
  margin: 5px;
  padding: 5px;
}

.message-list .right-align {
  text-align: right;
  background-color: #00a9a9a8;
  color: white;
  border-radius: 7.5px;
  margin: 5px;
  padding: 5px;
}

.message-form {
  display: flex;
  justify-content: space-between;
}

.message-input {
  border-radius: 6px;
  padding: 4px 8px;
  border: 2px solid #ccc;
  flex-grow: 1;
  margin-right: 10px;
}

.send-button {
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 15px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

@media (max-width: 285px) {
  .send-button {
    padding: 2px 4px;
  }
}

.send-button:hover {
  background-color: #008080cd;
  color: white;
}

/* modal popup chat box */

.modal1 {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
}


/* users list page */

.users-list {
  padding-top: 6rem;
  padding-bottom: 7rem;
}

.users-list .mb-3 {
  margin-bottom: 1rem !important;
}

.users-list .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.users-list .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.users-list .table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table td,
.table th {
  word-wrap: break-word;
  max-width: 150px;
}

.search-input-users-list {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.search-input-users-list input {
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-input-users-list input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media (max-width: 460px) {
  .search-input-users-list input {
    width: 100%;
  }
}

.users-list .table tbody tr {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.users-list .edit-button {
  border-radius: 10px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.users-list .edit-button:hover {
  background-color: #008080cd;
  color: white;
}


/* worklist page */

.reports-list {
  padding-top: 6rem;
  padding-bottom: 7rem;
}

.reports-list .mb-3 {
  margin-bottom: 1rem !important;
}

.reports-list .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.reports-list .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.reports-list .table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table td,
.table th {
  word-wrap: break-word;
  max-width: 150px;
}

.reports-list .table tbody tr {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.reports-list .edit-button {
  border-radius: 10px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.reports-list .edit-button:hover {
  background-color: #008080cd;
  color: white;
}

.reports-list .search-input-reports-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.reports-list .search-input-reports-list input {
  flex: 1 0 calc(40% - 10px);
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.reports-list .search-input-reports-list input[type="date"] {
  width: 100%;
  flex: 1 0 calc(10% - 10px);
  border-radius: 5px;
}

.reports-list .excel-btn {
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  color: #008080cd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  outline: none;
}

.reports-list .excel-btn:hover {
  color: white;
  background: #008080cd;
}

.my-assignment-button {
  border: none;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
}

.my-assignment-green {
  background-color: #008080cd;
}

.my-assignment-gray {
  background-color: #717378cd;
}

.input-group .input-list,
.input-group .excel-btn,
.input-group .my-assignment-button {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  outline: none;
}

.input-group .excel-btn,
.input-group .my-assignment-button {
  padding: 0 15px;
}

.search-input-reports-list input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media (max-width: 768px) {

  .reports-list .search-input-reports-list input,
  .reports-list .search-input-reports-list button {
    flex: 1 0 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {

  .reports-list .search-input-reports-list input,
  .reports-list .search-input-reports-list button {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 204px) {
  .input-list {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .input-list-select {
    width: 100%;
  }
}


/* add user page */

.user-add-button {
  border-radius: 10px;
  background-color: #bebdbd;
  border: none;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
}

.user-add-button:not(:disabled):hover {
  background-color: #008080cd;
  color: white;
  cursor: pointer;
}


/* user edit page */

.user-edit-save-button {
  border-radius: 10px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  margin-right: 5px;
}

.user-edit-save-button:not(:disabled):hover {
  background-color: #008080cd;
  color: white;
  cursor: pointer;
}

.user-delete-button {
  border-radius: 10px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  margin-right: 5px;
}

.user-delete-button:hover {
  background-color: #a30000cd;
  color: white;
  cursor: pointer;
}


/* new report */

.new-report-select {
  border-radius: 6px;
  padding: 4px 8px;
  border: none;
  margin-right: 10px;
  margin-top: 5px;
  width: 100%;
  margin-bottom: 5px;
  border: none;
  border-bottom-left-radius: 10x;
  border-bottom: 2px solid #ccc;
}

.new-report-button {
  border-radius: 10px;
  background-color: #0080809f;
  border: none;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-report-button:hover {
  background-color: #008080cd;
  color: white;
  cursor: pointer;
}

.dob-input {
  border-radius: 6px;
  padding: 4px 8px;
  border: none;
  margin-right: 10px;
  margin-top: 5px;
  width: 100%;
  margin-bottom: 5px;
  border: none;
  border-bottom-left-radius: 10x;
  border-bottom: 2px solid #ccc;
}

.mic-btn {
  margin-left: 5px;
  border-radius: 50%;
  border: none;
  height: 40px;
  width: 40px;
  text-decoration: none;
  display: inline-block;
  border: 5px solid rgb(199, 228, 244);
  font-size: 16px;
  transition-duration: 0.4s;
  margin-right: 5px;
  margin-bottom: 5px;
  vertical-align: middle;
  background-color: rgb(168, 168, 168);
}

.mic-btn:hover {
  background-color: #008080cd;
  color: white;
  cursor: pointer;
}

.mic-btn.listening {
  animation: neon 1s linear infinite alternate;
  box-shadow: 0 0 5px #4aedff, 0 0 10px #4aedff;
  cursor: not-allowed;
}

@keyframes neon {
  from {
    background-color: #1bc800d1;
    box-shadow: 0 0 5px #03d57a, 0 0 10px #03d57a;
  }

  to {
    background-color: #00dcd1cd;
    box-shadow: 0 0 5px #4aedff, 0 0 10px #4aedff;
  }
}


.auto-start-button {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 29px;
  margin-right: 5px;
}

.auto-start-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.auto-start-button .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.auto-start-button .slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.auto-start-button input:checked+.slider {
  background-color: #008080cd;
}

.auto-start-button input:checked+.slider:before {
  transform: translateX(20px);
}

.auto-start-button .slider.round {
  border-radius: 29px;
}

.auto-start-button .slider.round:before {
  border-radius: 50%;
}


/* edit report */

.checkbox-wrapper-31:hover .check {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.checkbox-wrapper-31 .background {
  fill: #ccc;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .check {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 input[type=checkbox] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  -appearance: none;
  -webkit-appearance: none;
}

.checkbox-wrapper-31 input[type=checkbox]:hover {
  cursor: pointer;
}

.checkbox-wrapper-31 input[type=checkbox]:checked+svg .background {
  fill: #008080cd;
}

.checkbox-wrapper-31 input[type=checkbox]:checked+svg .stroke {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 input[type=checkbox]:checked+svg .check {
  stroke-dashoffset: 0;
}


.chat-btn {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-decoration: none;
  display: inline-block;
  border: 3px solid rgb(199, 228, 244);
  font-size: 16px;
  transition-duration: 0.4s;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #008080a7;
  color: whitesmoke;
  position: fixed;
  bottom: 8%;
  right: 2%;
  z-index: 99999999;
}

.chat-btn:hover {
  background-color: #008080cd;
  color: white;
  cursor: pointer;
}


.delete-btn {
  border-radius: 10px;
  background-color: #717378cd;
  border: none;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  margin-top: 10px;
  margin-bottom: 10px;
}

.delete-btn:hover {
  background-color: #a30000cd;
  color: white;
  cursor: pointer;
}

.access-list {
  height: 70px;
  overflow: auto;
  border: transparent;
  padding: 10px;
  list-style-type: none;
}

.access-list li {
  margin-bottom: 10px;
}

.error-message {
  color: #008080cd;
}


/* feedback page */

/* Add this CSS to your existing styles or create a new CSS file */
.feedback-input {
  border: 2px solid #ccc;
  border-radius: 6px;
  padding: 4px 8px;
  margin-bottom: 10px;
  width: 100%;
}

.feedback-btn {
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
  background-color: #717378cd;
  color: #fff;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #008080cd;
}



.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}


.col-border {
  border-right: 1px solid #ccc;
}

.col-border:last-child {
  border-right: none;
}



.faq-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-question {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.faq-question strong {
  font-weight: bold;
}

.faq-toggle {
  background: none;
  border: none;
  padding: 0;
  font-size: 1.5rem;
  cursor: pointer;
}

.faq-toggle:focus {
  outline: none;
}

.faq-answer {
  padding: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
  display: none;
}

.faq-answer p {
  margin-bottom: 0;
}

.faq-question.active.faq-answer {
  display: block;
}

.faq-question.active.faq-toggle {
  transform: rotate(180deg);
}


.no-underline {
  text-decoration: none;
}

.no-underline:hover,
.no-underline:focus {
  text-decoration: none;
}


.link-item {
  display: inline-block;
  margin: 0.5rem;
}

.content-column {
  margin-top: 2rem;
}

.card-body-analyt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-link {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.custom-link:hover {
  text-decoration: underline;
}

.link-item {
  display: inline-block;
  margin: 5px;
}

@media (max-width: 576px) {
  .link-item {
    margin: 2px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .link-item {
    margin: 3px;
  }
}

@media (min-width: 769px) {
  .link-item {
    margin: 5px;
  }
}


@media (max-width: 600px) {
  .home-page .link {
    font-size: 18px;
    padding: 10px 50px;
  }

  .home-page h1 {
    font-size: 4em;
  }

  .wrapper {
    padding: 15px 20px;
  }

  .reports-list .table {
    margin-top: 15px;
  }
}


@media (max-width: 387px) {

  .reports-list .table {
    margin-top: 50px;
  }

}





/* Dark Mode Styles */
body.dark-mode {
  background-color: #121212d5;
  color: #e6e4e4;
}

body.dark-mode .header1 {
  background-color: rgba(36, 36, 36, 0.8);
}

body.dark-mode .nav-link:hover {
  color: #ececec;
}

body.dark-mode .footer {
  background-color: rgba(43, 43, 43, 0.8);
  color: #e6e4e4;
}

body.dark-mode .message-box,
body.dark-mode .message-input,
body.dark-mode .modal1 {
  background-color: rgba(36, 36, 36, 0.8);
  color: #e6e4e4;
}

body.dark-mode .dash-cards {
  background-color: #37a3a3ee;
}

body.dark-mode .card-title,
body.dark-mode .card-text,
body.dark-mode .welcome-links {
  color: #e6e4e4;
}

body.dark-mode .icon-dash {
  color: #e6e4e4;
}

body.dark-mode .faq-dash-adark {
  background-color: rgba(36, 36, 36, 0.8);
  color: #e6e4e4;
}

body.dark-mode .darktable {
  background-color: rgba(36, 36, 36, 0.8);
  color: #e6e4e4;
}

body.dark-mode .darktablecontent {
  background-color: rgba(255, 255, 255, 0);
  color: #e6e4e4;
}

body.dark-mode .dark-1btn {
  background-color: rgba(255, 255, 255, 0);
  color: #e6e4e4;
}

body.dark-mode .autocomplete-dropdown {
  color: black;
}

body.dark-mode .dateDatadark {
  background-color: rgba(255, 255, 255, 0);
  color: #e6e4e4;
}

body.dark-mode .darktEditUsercontent {
  background-color: rgba(108, 108, 108, 0.651);
}