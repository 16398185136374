.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
}

.modal-content {
    width: 50%;
    height: 40%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    animation: slide-up 0.5s ease-in-out;
}

.modal-close {
    background: transparent;
    border: none;
    cursor: pointer;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.modal-body {
    color: #a30000cd;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}